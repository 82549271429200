/**
 * = Images
 */

.image-lg {
    height: 12rem;
}

.image-md{
    height: 5rem;
}

.image-sm {
    height: 3rem;
}

.image-xs {
    height: 2rem;
}

.img-thumbnail {
    border-width: $border-width-md;
    box-shadow: none;
}

.full-image{
    height:100%;
}

@include media-breakpoint-up(sm){
    .effect-img-2 {
        position: absolute;
        right: 5rem;
        top: 19%;
        z-index: 2;
        margin: 0;
    }

    .effect-img-1, .effect-img-2 {
        width: 350px;
        height: auto;
    }
}

@include media-breakpoint-down(md){
    .effect-img-2{
        right:.425rem;
        top:0;
    }
}
