/**
 * = Accordions
 */

.accordion .card {
    margin-bottom: 1.5rem;
    &:hover{
        background-color: $soft;
    }
}

.accordion-panel-header {
    @include display-flex();
    @include justify-content(space-between);
    @include align-items(center);
    user-select: none;

    .icon {
        color: $secondary;
        transition: $transition-base;
        span {
            font-size: $font-size-sm;
        }
    }
    .icon-title {
        margin-right: 1rem;
        span {
            margin-right: 1rem;
        }

        & + .icon {
            height: 1rem;
        }
    }

    &[aria-expanded="true"]{
        > .icon {
            @include transform(rotateZ(45deg));
        }
    }

    &[aria-expanded="true"], &:hover{
        > * {
        color: theme-color('dark');
            i{ 
                fill: theme-color('dark');
            }
        }
    }

    &:hover {
        cursor: pointer;
    }
}
